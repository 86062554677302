import { TwitterSigner } from './TwitterSigner';

/**
 * Manage autnetication to the Twitter API using the hidden Android consumer keys
 * @author Vladimir Metelitsa (https://github.com/green-cat)
 * @date 09.06.2023
 */
export class TwitterScrape {
    private oauth_consumer_key = "3nVuSoBZnx6U4vzUxf5w";
    private oauth_consumer_secret = "Bcs59EFbbsdF6Sl9Ng71smgStWEGwXXKSjYvPVt7qys";
    private oauth_token: string;
    private oauth_token_secret: string;

    public async init() {
        const bearer = await this.mobileOauth();
        console.log(`Bearer: ${bearer}`);
        const guest_token = await this.guestActivate(bearer);
        console.log(`Guest token: ${guest_token}`);
        const flow_token = await this.welcomeFlow(bearer, guest_token);
        console.log(`Flow token: ${flow_token}`);
        await this.flow(bearer, guest_token, flow_token);
    }

    private async mobileOauth() {        
        const resp = await fetch(`https://hello-world-dry-lake-d1b4.green-cat.workers.dev/?https://api.twitter.com/oauth2/token?grant_type=client_credentials`, {
            method: "POST",
            headers: {
                "x-auth": "Basic M25WdVNvQlpueDZVNHZ6VXhmNXc6QmNzNTlFRmJic2RGNlNsOU5nNzFzbWdTdFdFR3dYWEtTall2UFZ0N3F5cw==",
                "Content-Type": "application/x-www-form-urlencoded"
            },
        });
        const respj = await resp.json();
        return respj.access_token;
    }

    private async guestActivate(bearer) {
        const resp = await fetch(`https://hello-world-dry-lake-d1b4.green-cat.workers.dev/?https://api.twitter.com/1.1/guest/activate.json`, {
            method: "POST",
            headers: {
                "x-auth": `Bearer ${bearer}`,
                "Content-Type": "application/json"
            }
        });
        const respj = await resp.json();

        return respj.guest_token;
    }

    private async welcomeFlow(bearer, guest_token) {
        const resp = await fetch(`https://hello-world-dry-lake-d1b4.green-cat.workers.dev/?https://api.twitter.com/1.1/onboarding/task.json`, {
            method: "POST",
            headers: {
                "x-auth": `Bearer ${bearer}`,
                "Content-Type": "application/json",
                "User-Agent": "TwitterAndroid/99",
                "X-Guest-Token": guest_token,
                "X-Twitter-Auth-Type": "OAuth2Client",
                "X-Twitter-Active-User": "yes",
                "X-Twitter-Client-Language": "en"
            },
            body: `{"flow_name":"welcome","input_flow_data":{"flow_context":{"debug_overrides":{},"start_location":{"location":"splash_screen"}}}}`
        });

        const respj = await resp.json();

        console.log(respj);

        return respj.flow_token;
    }

    private async flow(bearer, guest_token, flow_token) {
        const resp = await fetch(`https://hello-world-dry-lake-d1b4.green-cat.workers.dev/?https://api.twitter.com/1.1/onboarding/task.json`, {
            method: "POST",
            headers: {
                "x-auth": `Bearer ${bearer}`,
                "Content-Type": "application/json",
                "User-Agent": "TwitterAndroid/99",
                "X-Guest-Token": guest_token,
                "X-Twitter-Auth-Type": "OAuth2Client",
                "X-Twitter-Active-User": "yes",
                "X-Twitter-Client-Language": "en"
            },
            body: JSON.stringify({
                "flow_token": flow_token,
                "subtask_inputs": [{
                    "subtask_id": "NextTaskOpenLink"
                }]
            })
        });

        const respj = await resp.json();

        this.oauth_token = respj.subtasks[0].open_account.oauth_token
        this.oauth_token_secret = respj.subtasks[0].open_account.oauth_token_secret
    }

    public fixedEncodeURIComponent(str) {
        return encodeURIComponent(str).replace(/[!'()*]/g, function(c) {
          return '%' + c.charCodeAt(0).toString(16);
        });
    }

    public async search(query: string, cursor: string) {
        if(this.oauth_token === null || this.oauth_token === "" || this.oauth_token === undefined) await this.init();

        if(cursor == "-1") {
            cursor = null;
        }

        const eq = this.fixedEncodeURIComponent(query);
        const cur = this.fixedEncodeURIComponent(cursor);

        let url = `https://api.twitter.com/2/search/adaptive.json?q=${eq}&tweet_search_mode=live&f=tweets&include_can_media_tag=1&include_ext_alt_text=true&include_quote_count=true&include_reply_count=1&tweet_mode=extended&include_entities=true&include_user_entities=true&include_ext_media_availability=true&send_error_codes=true&simple_quoted_tweet=true&count=100&query_source=typed_query&spelling_corrections=1&ext=mediaStats%2ChighlightedLabel`;
        if(cursor !== null) url += `&cursor=${cur}`;
        const twitterSign = new TwitterSigner;

        const params = {
            "q": eq,
            "tweet_search_mode": "live",
            "f": "tweets",
            "include_can_media_tag": 1,
            include_ext_alt_text: true,
            include_quote_count: true,
            include_reply_count: 1,
            tweet_mode: "extended",
            include_entities: "true",
            include_user_entities: "true",
            include_ext_media_availability: "true",
            send_error_codes: "true",
            simple_quoted_tweet: "true",
            count: 100,
            query_source: "typed_query",
            spelling_corrections: 1,
            ext: "mediaStats%2ChighlightedLabel",
        }

        if(cursor !== null) {
            params["cursor"] = cur;
        }

        const sign = twitterSign._buildAuthorizationHeaders(await(twitterSign._prepareParameters(this.oauth_token, this.oauth_token_secret, "GET", url, params)));
        
        console.log(sign);
        try {
            const resp = await fetch(`https://hello-world-dry-lake-d1b4.green-cat.workers.dev/?${url}`, {
                headers: {
                    'x-auth': sign
                }
            });
            const respj = await resp.json();
            return respj;

            // console.log(respj);
        } catch(err) {
            console.error(err.response);
        }
    }
}
