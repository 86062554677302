import querystring from 'querystring-browser';
import URL from 'url';

/* eslint-disable */
/**
 * Create signatures for the Twitter API OAuth v1.0a
 * @author Vladimir Metelitsa (https://github.com/green-cat)
 * @date 09.06.2023
 */

export class TwitterSigner {
    private oauth_consumer_key: string = "3nVuSoBZnx6U4vzUxf5w";
    private oauth_consumer_secret: string = "Bcs59EFbbsdF6Sl9Ng71smgStWEGwXXKSjYvPVt7qys";

    public _buildAuthorizationHeaders(orderedParameters) {
        var authHeader="OAuth ";
        const _oauthParameterSeperator = ", "
      
        for( var i= 0 ; i < orderedParameters.length; i++) {
           // Whilst the all the parameters should be included within the signature, only the oauth_ arguments
           // should appear within the authorization header.
           if( this._isParameterNameAnOAuthParameter(orderedParameters[i][0]) ) {
            authHeader+= "" + this._encodeData(orderedParameters[i][0])+"=\""+ this._encodeData(orderedParameters[i][1])+"\""+ _oauthParameterSeperator;
           }
        }
      
        authHeader= authHeader.substring(0, authHeader.length-_oauthParameterSeperator.length);
        return authHeader;
      }

      private _isParameterNameAnOAuthParameter(parameter) {
        var m = parameter.match('^oauth_');
        if( m && ( m[0] === "oauth_" ) ) {
          return true;
        }
        else {
          return false;
        }
      };

    private _encodeData(toEncode) {
        if( toEncode == null || toEncode == "" ) return ""
        else {
           var result= encodeURIComponent(toEncode);
           // Fix the mismatch between OAuth's  RFC3986's and Javascript's beliefs in what is right and wrong ;)
           return result.replace(/\!/g, "%21")
                        .replace(/\'/g, "%27")
                        .replace(/\(/g, "%28")
                        .replace(/\)/g, "%29")
                        .replace(/\*/g, "%2A");
        }
    }

    public async _prepareParameters( oauth_token, oauth_token_secret, method, url, extra_params ) {
        var oauthParameters= {
            "oauth_timestamp":        this._getTimestamp(),
            "oauth_nonce":            "0",
            "oauth_version":          "1.0",
            "oauth_signature_method": "HMAC-SHA1",
            "oauth_consumer_key":     this.oauth_consumer_key
        };
      
        if( oauth_token ) {
          oauthParameters["oauth_token"]= oauth_token;
        }
      
        var sig;
          if( extra_params ) {
            for( var key in extra_params ) {
              if (extra_params.hasOwnProperty(key)) oauthParameters[key]= extra_params[key];
            }
          }
          var parsedUrl= (URL as any).parse( url, false );
      
          if( parsedUrl.query ) {
            var key2;
            var extraParameters= querystring.parse(parsedUrl.query);
            for(var key in extraParameters ) {
              var value= extraParameters[key];
                if( typeof value == "object" ){
                  // TODO: This probably should be recursive
                  for(key2 in value){
                    oauthParameters[key + "[" + key2 + "]"] = value[key2];
                  }
                } else {
                  oauthParameters[key]= value;
                }
              }
          }
      
          sig = await this._getSignature( method,  url,  this._normaliseRequestParams(oauthParameters), oauth_token_secret);
      
        var orderedParameters= this._sortRequestParams( this._makeArrayOfArgumentsHash(oauthParameters) );
        orderedParameters[orderedParameters.length]= ["oauth_signature", sig];
        return orderedParameters;
    }

    private _getTimestamp() {
        return Math.floor( (new Date()).getTime() / 1000 );
    }

    private _normaliseRequestParams(args) {
        var argument_pairs= this._makeArrayOfArgumentsHash(args);
        // First encode them #3.4.1.3.2 .1
        for(var i=0;i<argument_pairs.length;i++) {
          argument_pairs[i][0]= this._encodeData( argument_pairs[i][0] );
          argument_pairs[i][1]= this._encodeData( argument_pairs[i][1] );
        }
      
        // Then sort them #3.4.1.3.2 .2
        argument_pairs= this._sortRequestParams( argument_pairs );
      
        // Then concatenate together #3.4.1.3.2 .3 & .4
        var args: any = "";
        for(var i=0;i<argument_pairs.length;i++) {
            args+= argument_pairs[i][0];
            args+= "="
            args+= argument_pairs[i][1];
            if( i < argument_pairs.length-1 ) args+= "&";
        }
        return args;
    }

    private async _getSignature(method, url, parameters, tokenSecret) {
        var signatureBase= this._createSignatureBase(method, url, parameters);
        return await this._createSignature( signatureBase, tokenSecret );
    }

    private _sortRequestParams(argument_pairs) {
        // Sort by name, then value.
        argument_pairs.sort(function(a,b) {
            if ( a[0]== b[0] )  {
              return a[1] < b[1] ? -1 : 1;
            }
            else return a[0] < b[0] ? -1 : 1;
        });
      
        return argument_pairs;
    }

    private _makeArrayOfArgumentsHash(argumentsHash) {
        var argument_pairs= [];
        for(var key in argumentsHash ) {
          if (argumentsHash.hasOwnProperty(key)) {
             var value= argumentsHash[key];
             if( Array.isArray(value) ) {
               for(var i=0;i<value.length;i++) {
                 argument_pairs[argument_pairs.length]= [key, value[i]];
               }
             }
             else {
               argument_pairs[argument_pairs.length]= [key, value];
             }
          }
        }
        return argument_pairs;
    }

    private _createSignatureBase(method, url, parameters) {
        url= this._encodeData( this._normalizeUrl(url) );
        parameters= this._encodeData( parameters );
        return method.toUpperCase() + "&" + url + "&" + parameters;
    }

    

    private _normalizeUrl(url) {
        var parsedUrl= (URL as any).parse(url, true)
         var port ="";
         if( parsedUrl.port ) {
           if( (parsedUrl.protocol == "http:" && parsedUrl.port != "80" ) ||
               (parsedUrl.protocol == "https:" && parsedUrl.port != "443") ) {
                 port= ":" + parsedUrl.port;
               }
         }
      
        if( !parsedUrl.pathname  || parsedUrl.pathname == "" ) parsedUrl.pathname ="/";
      
        return parsedUrl.protocol + "//" + parsedUrl.hostname + port + parsedUrl.pathname;
    }

    private async _createSignature(signatureBase, tokenSecret) {
        tokenSecret= this._encodeData( tokenSecret );
         // consumerSecret is already encoded
        //  var key= this._encodeData(this.oauth_consumer_secret) + "&" + tokenSecret;
      
        //  var hash= ""
        //     hash = crypto.createHmac("sha1", key).update(signatureBase).digest("base64");

        const secret = this._encodeData(this.oauth_consumer_secret) + "&" + tokenSecret;
        const enc = new TextEncoder();
        const body = signatureBase;
        const algorithm = { name: "HMAC", hash: "SHA-1" };
    
        const key = await crypto.subtle.importKey(
            "raw",
            enc.encode(secret),
            algorithm,
            false,
            ["sign", "verify"]
        );
    
        const signature = await crypto.subtle.sign(
            algorithm.name,
            key,
            enc.encode(body)
        );
    
        // convert buffer to byte array
        // const hashArray = Array.from(new Uint8Array(signature));
    
        // // convert bytes to hex string
        // const digest = hashArray
        //     .map((b) => b.toString(16).padStart(2, "0"))
        //     .join("");

        let b = new Uint8Array(signature);
        // base64 digest
        return btoa(String.fromCharCode(...b));

    
        // console.log(digest);
                


        //  return digest;
      }
}