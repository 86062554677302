
import { Options, Vue } from 'vue-class-component';
import JsonCSV from 'vue-json-csv';
import { Document, ImageRun, Packer, Paragraph } from "docx";
import { saveAs } from 'file-saver';
import ExcelJS from "exceljs";

import HelloWorld from '@/components/HelloWorld.vue'; // @ is an alias to /src

import { Tweet, TwitterAPIResult } from "@/utils/TwitterScraper";
import { TwitterScrape } from '@/utils/TwitterScrape';

@Options({
    components: {
        HelloWorld,
        JsonCSV,
    },
})
export default class HomeView extends Vue {
    scrape = new TwitterScrape;
    downloading = false;
    downloadDone = false;
    generatingDoc = false;
    tweetsRetrieved = 0;
    tweetsDoneForDoc = 0;
    andField = "";
    exactPhraseField = "";
    orField = "";
    notField = "";
    hashtagField = "";
    fromField = "";
    untilField = "";
    languageField = "any";

    downloadedTweets: Tweet[] = [];


    /**
     * Returns a string representing the query parameters to be used in a search.
     *
     * @return {string} The query string to be used in a search.
     */
    get queryString(): string {
        let queryString = "";
        // Handle AND
        queryString += this.andField;

        // Handle Exact
        if(this.exactPhraseField !== "") 
        {
            queryString += ` "${this.exactPhraseField}"`;
        }
    
        // Handle OR
        if(this.orField !== "") {
            const orArray = this.orField.split(" ").filter(e =>  e)
            if(orArray.length > 0) {
                queryString += " (";
                for(let i = 0; i < orArray.length; i++) {
                    orArray[i] = orArray[i].trim();
                    queryString += `${orArray[i]}`;
                    if(i < orArray.length - 1) {
                        queryString += " OR ";
                    }
                }
                queryString += ")";
            }
        }

        // Handle Hashtags
        if(this.hashtagField !== "") {
            const hashtagArray = this.hashtagField.split(" ").filter(e => e)
            if(hashtagArray.length > 0) {
                queryString += " (";
                for(let i = 0; i < hashtagArray.length; i++) {
                    hashtagArray[i] = hashtagArray[i].replace("#", "").trim();
                    queryString += `#${hashtagArray[i]}`;
                    if(i < hashtagArray.length - 1) {
                        queryString += " OR ";
                    }
                }
                queryString += ")";
            }
        }

        // Handle NOT
        if(this.notField !== "") {
            const notArray = this.notField.split(" ").filter(e =>  e)
            if(notArray.length > 0) {
                for(const n of notArray) queryString += ` -${n}`;
            }
        }

        // Handle language
        if(this.languageField !== "any")
        {
            queryString += ` lang:${this.languageField}`;
        }

        // Handle FROM
        if(this.fromField !== "") {
            queryString += ` since:${this.fromField}`;
        }

        // Handle UNTIL
        if(this.untilField !== "") {
            queryString += ` until:${this.untilField}`;
        }
    
        return queryString.trim().trim().trim();
    }

    /**
     * Downloads tweets based on a given query string. If the query string is empty,
     * an alert will be displayed. The downloaded tweets will be stored in the downloadedTweets
     * list. 
     *
     * @return {Promise<void>} - Returns nothing after the tweets are downloaded.
     */
    async download(): Promise<void> {
        if(this.queryString.trim() === "") {
            alert("Please enter a query");
            return;
        }
        this.tweetsRetrieved = 0;
        this.downloadedTweets = [];
        this.downloading = true;
        const query = this.queryString;

        let { cursor, numNewTweets, newTweets } = await this.getTweetSet(query);
        this.downloadedTweets = [...this.downloadedTweets, ...newTweets];
        this.tweetsRetrieved += numNewTweets;

        // eslint-disable-next-line
        while(true) {
            console.log(`Be loopin' with ${cursor}`);
            if(numNewTweets === 0) break;
            ({ cursor, numNewTweets, newTweets } = await this.getTweetSet(query, cursor));
            this.downloadedTweets = [...this.downloadedTweets, ...newTweets];
            this.tweetsRetrieved += numNewTweets;
        }
        console.log(`Retrieved ${this.tweetsRetrieved} tweets`);
        await this.sleep(2500);

        this.downloading = false;
        this.downloadDone = true;
    }

    /**
     * Sleeps for a given number of milliseconds.
     *
     * @param {number} ms - The number of milliseconds to sleep for.
     * @return {Promise} A Promise that resolves after the specified number of milliseconds.
     */
    sleep(ms: number): Promise<void> {
        return new Promise((resolve) => {
            setTimeout(resolve, ms);
        });
    }

    /**
     * Asynchronously retrieves a set of tweets that match a given query and cursor.
     *
     * @param {string} query - The query to use for the search. Default is an empty string.
     * @param {string} cursor - The cursor to use for pagination. Default is "-1".
     * @return {Promise<{ cursor: string, numNewTweets: number, newTweets: Tweet[] }>} The retrieved tweets, along with pagination information.
     */
    async getTweetSet(query = "", cursor = "-1"): Promise<{ cursor: string; numNewTweets: number; newTweets: Tweet[]; }> {
        await this.sleep(500);
        console.log(`Query: query`);
        const data: TwitterAPIResult = await this.scrape.search(query, cursor);

        if(data === undefined) {
            console.log("No data");
            return { cursor: "-1", numNewTweets: 0, newTweets: [] };
        }

        const numEntries = data.timeline.instructions[0].addEntries.entries.length;
        if(numEntries === 0) {
            console.log("No entries");
            return { cursor: "-1", numNewTweets: 0, newTweets: [] };
        }
        console.log(data.timeline.instructions[0].addEntries.entries);
        console.log(data.timeline.instructions[0].addEntries.entries[numEntries-1]);

        let scroll = "-1";
        const entry = data.timeline.instructions[0].addEntries.entries[numEntries-1];
        console.log(`Entry: entry`);
        console.log(entry.entryId === "sq-cursor-bottom");
        if(entry.entryId === "sq-cursor-bottom") {
            scroll = entry.content.operation.cursor.value;
        } else {
            console.log(data.timeline.instructions[2].replaceEntry);
            scroll = data.timeline.instructions[2].replaceEntry.entry.content.operation.cursor.value;
        }
        const numNewTweets = Object.keys(data.globalObjects.tweets).length;
        const newTweets = this.parseTweets(data);
        
        return { cursor: scroll, numNewTweets, newTweets };
    }

    /**
     * Parses Twitter API result data to create an array of Tweet objects.
     *
     * @param {TwitterAPIResult} data - the data to parse
     * @return {Tweet[]} an array of Tweet objects
     */
    parseTweets(data: TwitterAPIResult): Tweet[] {
        const tweets: Tweet[] = [];
        // const users: TwitterUser[] = [];
        const tweetsRaw = data.globalObjects.tweets;
        const usersRaw = data.globalObjects.users;

        // // Handle users
        // for(const key in usersRaw) {
        //   const raw = usersRaw[key];
        //   users.push({
        //     id: raw.id,
        //     name: raw.name,
        //     username: raw.screen_name,
        //     // location: string,
        //     // description: string,
        //     // url: null|string,
        //     // protected: boolean,
        //     // followers_count: number,
        //     // created_at: string,
        //     // favourites_count: number,
        //     // verified: boolean,
        //     // statuses_count: number,
        //     // media_count: number,
        //     // lang: null|string,
        //     // profile_background_image_url_https: string,
        //     // profile_image_url: string,
        //   });
        // }

        // Handle tweets
        for(const key in tweetsRaw) {
            const raw = tweetsRaw[key];
            if(raw.truncated) {
                console.log(`Truncated tweet found`);
                console.log(raw);
            }

            // Handle date
            const dateObj = new Date(raw.created_at);
            const dateIsoString = dateObj.toISOString();

            // Handle user
            const user = usersRaw[raw.user_id_str];
            let username = "";
            let name = "";
            if(user !== undefined) {
                username = user.screen_name;
                name = user.name;
            }

            const texty = raw.full_text ?? raw.text;
            
            tweets.push({
                id: raw.id_str,
                conversation_id: raw.conversation_id_str,
                created_at: dateIsoString,
                // date + time separate fields
                user_id: raw.user_id_str,
                user_username: username,
                user_name: name,
                text: texty,
                // text: raw.text,
                language: raw.lang,
                reply_count: raw.reply_count,
                retweet_count: raw.retweet_count,
                favorite_count: raw.favorite_count,
                quote_count: raw.quote_count,
                is_retweet: raw.retweeted,
                photos: raw.extended_entities ? raw.extended_entities.media.map(m => m.media_url_https) : [],

                // hashtags
                // mentions?
                // media? photos
                // source?
                // in_reply_to_status_id: number|null;
                // in_reply_to_user_id: number|null;
                // in_reply_to_screen_name: string|null;
                // geo: null,
                // coordinates: null,
                // place: null,
                // is_quote_status: boolean,
                // supplemental_language: null
            });
            this.tweetsRetrieved++;
        }
        return tweets;
    }

    /**
     * Asynchronously creates a Word document containing the results of a Twitter scraper search.
     *
     * @return {Promise<void>} - a Promise that resolves when the document is generated
     */
    async createWordDocument(): Promise<void> {
        this.generatingDoc = true;
        const paras: Paragraph[] = [];

        paras.push(new Paragraph(`Twitter scraper results for: ${this.queryString}`));
        paras.push(new Paragraph(``));
        paras.push(new Paragraph(``));

        for(const tweet of this.downloadedTweets) {
            paras.push(new Paragraph(`Tweet ID: ${tweet.id}`));
            paras.push(new Paragraph(`Tweet Conversation ID: ${tweet.conversation_id}`));
            paras.push(new Paragraph(`Date: ${tweet.created_at}`));
            paras.push(new Paragraph(`By: ${tweet.user_username} ${tweet.user_name}`));
            paras.push(new Paragraph(`Favourites: ${tweet.favorite_count}`));
            paras.push(new Paragraph(`Retweets: ${tweet.retweet_count}`));
            paras.push(new Paragraph(`Replies: ${tweet.reply_count}`));
            paras.push(new Paragraph(`Quote tweets: ${tweet.quote_count}`));
            paras.push(new Paragraph(`Is retweet?: ${tweet.is_retweet ? "Yes" : "No"}`));
            paras.push(new Paragraph(`${tweet.text}`));

            if(tweet.photos.length > 0) {
                for(const photo of tweet.photos) {
                    const b64 = await this.getBase64FromUrl(photo) as string;
                    const size = await this.getImageDimensions(b64) as { w: number; h: number };
                    console.log(size);
                    const image = new ImageRun({
                        data: b64,
                        transformation: {
                            width: size.w,
                            height: size.h,
                        }
                    });
                    const para = new Paragraph({children: [image]});
                    paras.push(para);
                }
            }


            paras.push(new Paragraph(`========`));
            this.tweetsDoneForDoc++;
        }

        // this.wordDocumentCreated = true;
        const doc = new Document({
            creator: "Twitter Scraper",
            description: "Scrape results",
            title: "Scrape results",
            sections: [
                {
                    properties: {},
                    children: [...paras],
                }
            ]
        });
        // let title = new Paragraph(`Detailed Report for`).title().center();

        Packer.toBlob(doc).then((buffer) => {
            saveAs(buffer, "My Document.docx");
        });
    }

    /**
     * Creates an excel document based on the results of a Twitter scraper search.
     */
    async createExcelDocument(): Promise<void> {
        const workbook = new ExcelJS.Workbook;
        const sheet = workbook.addWorksheet('Results');
        // Add columns
        sheet.columns = [
            { header: 'Tweet ID', key: 'id' },
            { header: 'Conversation ID', key: 'conversation_id' },
            { header: 'Date', key: 'created_at' },
            { header: 'By', key: 'user_username' },
            { header: 'Username', key: 'user_name' },
            { header: 'Favourites', key: 'favorite_count' },
            { header: 'Retweets', key: 'retweet_count' },
            { header: 'Replies', key: 'reply_count' },
            { header: 'Quote tweets', key: 'quote_count' },
            { header: 'Is retweet?', key: 'is_retweet' },
            { header: 'Text', key: 'text' },
            { header: 'Photos', key: 'photos' },
        ];

        // Add data
        for(const tweet of this.downloadedTweets) {
            // Make string from photo urls array
            const photos = tweet.photos.join(", ")
            sheet.addRow({
                id: String(tweet.id),
                conversation_id: String(tweet.conversation_id),
                created_at: tweet.created_at,
                user_username: tweet.user_username,
                user_name: tweet.user_name,
                favorite_count: tweet.favorite_count,
                retweet_count: tweet.retweet_count,
                reply_count: tweet.reply_count,
                quote_count: tweet.quote_count,
                is_retweet: tweet.is_retweet,
                text: tweet.text,
                photos
            })
        }

        // Export excel document
        workbook.xlsx.writeBuffer().then((buffer) => {
            saveAs(new Blob([buffer], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" }), "Results.xlsx");
        })
    }

    /**
     * Asynchronously fetches a resource from a URL and returns its base64 encoding.
     *
     * @param {string} url - The URL of the resource to fetch.
     * @return {Promise<string|ArrayBuffer>} A Promise that resolves to the base64 encoding of the fetched resource.
     */
    async getBase64FromUrl(url: string): Promise<string|ArrayBuffer> {
        const data = await fetch(url);
        const blob = await data.blob();
        return new Promise((resolve) => {
            const reader = new FileReader();
            reader.readAsDataURL(blob); 
            reader.onloadend = () => {
                const base64data = reader.result;   
                resolve(base64data);
            }
        });
    }

    /**
     * Asynchronously retrieves the dimensions of an image file (base 64).
     *
     * @param {string} file - The image file to retrieve the dimensions of.
     * @return {Promise<{w: number, h: number}>} A promise that resolves to an object with the width and height of the image.
     */
    async getImageDimensions(file: string) {
        return new Promise (function (resolved) {
            var i = new Image();
            i.onload = function(){
                resolved({w: i.width, h: i.height})
            };
            i.src = file
        });
    }
}

